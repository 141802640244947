<template>
  <div>
    <page-header has-back></page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="10">
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="200px">
              <el-form-item label="密码" prop="dealPassword">
                <el-input type="password" v-model="editForm.dealPassword"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="confirmDealPassword">
                <el-input type="password" v-model="editForm.confirmDealPassword"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSave" :loading="editLoading">保存</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>

import { AccountApi } from '@/api'
import { dealPasswordValidator } from '@/utils/validator'

export default {
  name: 'DealPassword',
  data () {
    return {
      editForm: {
        dealPassword: '',
        confirmDealPassword: ''
      },
      rules: {
        dealPassword: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: dealPasswordValidator
          }
        ],
        confirmDealPassword: [
          {
            required: true,
            message: '请输入确认密码',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: dealPasswordValidator
          }
        ]
      },
      editLoading: false
    }
  },
  methods: {
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          AccountApi.saveDealPassword(this.editForm).then(() => {
            this.editLoading = false
            this.$message.success('设置成功')
            this.$router.back()
          }).catch(() => {
            this.editLoading = false
          })
        } else {
          return false
        }
      })
    }
  },
  created: function () {
  }
}
</script>

<style scoped>

</style>
