<template>
  <el-card shadow="never" class="report-card">
    <div slot="header" class="clearfix report-card-header">
      <span>快捷入口</span>
    </div>
    <div class="button-view">
      <el-button plain @click="handleGotoPage('/oms/order/vip/create')">开通VIP</el-button>
      <el-button plain @click="handleGotoPage('/user/list')">会员列表</el-button>
    </div>
  </el-card>
</template>
<script>

export default {
  name: 'ShortcutCard',
  data () {
    return {}
  },
  methods: {
    handleGotoPage (url) {
      this.$router.push(url)
    }
  }
}
</script>

<style scoped lang="less">
.button-view {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .button-item {
    margin-right: 10px;
  }
}

.report-card {
  min-height: 200px;
}
</style>
