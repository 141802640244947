<template>
  <div>
    <page-header>
      <template v-slot:right>
        <el-button type="primary" @click="handleEdit()">添加发票抬头</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" :model="searchForm">
              <el-form-item prop="userId">
                <remote-select v-model="searchForm.userId"
                               width="300"
                               width-input="250px"
                               placement="bottom"
                               url="/user/list"
                               placeholder="用户"
                               :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                               label="nickName"
                               value-key="id"
                               :size="5"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.header" placeholder="发票抬头" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.taxNumber" placeholder="纳税人识别号" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button plain @click="handleGetTableInfo">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table :data="tableInfo" style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" label="#" width="40" align="center"></el-table-column>
              <el-table-column prop="nickName" label="用户" width="250">
                <template slot-scope="scope">
                  {{ scope.row.nickName }}（{{ scope.row.mobile }}）
                </template>
              </el-table-column>
              <el-table-column label="抬头类型" width="100">
                <template slot-scope="scope">
                  {{ scope.row.headerType === 1 ? '个人' : scope.row.headerType === 2 ? '企业' : '组织' }}
                </template>
              </el-table-column>
              <el-table-column label="发票类型" width="120">
                <template slot-scope="scope">
                  {{ scope.row.invoiceType === 1 ? '增值税普通发票' : '增值税专用发票' }}
                </template>
              </el-table-column>
              <el-table-column prop="header" label="发票抬头" width="200"></el-table-column>
              <el-table-column prop="taxNumber" label="纳税人识别号" width="180"></el-table-column>
              <el-table-column prop="bankName" label="基本开户行" width="220"></el-table-column>
              <el-table-column prop="bankAccount" label="基本开户账号" width="160"></el-table-column>
              <el-table-column prop="companyAddress" label="企业注册地址" width="120"></el-table-column>
              <el-table-column prop="companyTel" label="企业联系电话" width="140"></el-table-column>
              <el-table-column prop="creatorName" label="创建人" width="100"></el-table-column>
              <el-table-column prop="creationTime" label="创建时间" width="180"></el-table-column>
              <el-table-column prop="creatorDeptName" label="部门" width="180"></el-table-column>
              <el-table-column fixed="right" label="操作" width="80">
                <template slot-scope="scope">
                  <el-button @click="handleEdit(scope.row.id)" type="text" size="small">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.page"
                :page-sizes="[10,20,50,100]"
                :page-size="searchForm.size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>

        <el-dialog
            title="编辑"
            :visible.sync="dialogVisible"
            width="40%">
          <div>
            <el-col :span="24">
              <el-form :model="editForm" :rules="rules" ref="editForm" label-width="160px">
                <el-form-item label="选择用户" prop="userId">
                  <remote-select v-model="editForm.userId"
                                 width="300"
                                 width-input="300px"
                                 placement="bottom"
                                 url="/user/list"
                                 :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                                 label="nickName"
                                 value-key="id"
                                 :size="5"
                                 :readonly="!!editForm.id"
                                 :clearable="!!editForm.id"
                  ></remote-select>
                </el-form-item>
                <el-form-item label="抬头类型" prop="headerType">
                  <el-radio-group v-model="editForm.headerType">
                    <!--                  <el-radio :label="1">个人</el-radio>-->
                    <el-radio :label="2">企业</el-radio>
                    <!--                  <el-radio :label="3">组织</el-radio>-->
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="发票类型" prop="invoiceType">
                  <el-radio-group v-model="editForm.invoiceType">
                    <el-radio :label="1">增值税普通发票</el-radio>
                    <!--                  <el-radio :label="2">增值税专用发票</el-radio>-->
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="发票抬头" prop="header">
                  <el-input v-model="editForm.header" class="invoice-input"></el-input>
                </el-form-item>
                <el-form-item label="纳税人识别号" prop="taxNumber">
                  <el-input v-model="editForm.taxNumber" class="invoice-input"></el-input>
                </el-form-item>
                <el-form-item label="基本开户行">
                  <el-input v-model="editForm.bankName" class="invoice-input"></el-input>
                </el-form-item>
                <el-form-item label="基本开户行帐号">
                  <el-input v-model="editForm.bankAccount" class="invoice-input"></el-input>
                </el-form-item>
                <el-form-item label="企业注册地址">
                  <el-input v-model="editForm.companyAddress" class="invoice-input"></el-input>
                </el-form-item>
                <el-form-item label="企业联系电话">
                  <el-input v-model="editForm.companyTel" class="invoice-input"></el-input>
                </el-form-item>
              </el-form>
            </el-col>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleSave()" :loading="saveLoading">保 存</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
import { UserInvoiceHeaderApi } from '@/api'

const defaultForm = () => {
  return {
    userId: '',
    headerType: 2,
    invoiceType: 1,
    header: '',
    taxNumber: '',
    bankName: '',
    bankAccount: '',
    companyAddress: '',
    companyTel: ''
  }
}
const defaultSearchForm = () => {
  return {
    userId: '',
    header: '',
    taxNumber: '',
    page: 1,
    size: 10
  }
}
export default {
  name: 'InvoiceHeader',
  data () {
    return {
      searchForm: defaultSearchForm(),
      total: 0,
      tableInfo: [],
      editForm: defaultForm(),
      dialogVisible: false,
      rules: {
        userId: [{
          required: true,
          message: '请选择用户',
          trigger: 'change'
        }],
        headerType: [{
          required: true,
          message: '请选择抬头类型',
          trigger: 'change'
        }],
        invoiceType: [{
          required: true,
          message: '请选择发票类型',
          trigger: 'change'
        }],
        header: [{
          required: true,
          message: '发票抬头不能为空',
          trigger: 'blur'
        }],
        taxNumber: [{
          required: true,
          message: '纳税人识别号不能为空',
          trigger: 'blur'
        }]
      },
      saveLoading: false
    }
  },
  methods: {
    /**
     * 获取表格信息
     */
    handleGetTableInfo () {
      const params = {
        ...this.searchForm,
        page: this.searchForm.page - 1
      }
      UserInvoiceHeaderApi.list(params).then(res => {
        this.tableInfo = res.rows
        this.total = res.total
      })
    },
    handleSizeChange (size) {
      this.searchForm.size = size
      this.handleGetTableInfo()
    },
    handleCurrentChange (page) {
      this.searchForm.page = page
      this.handleGetTableInfo()
    },
    /**
     * 编辑信息
     * @param id
     */
    handleEdit (id) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.editForm.resetFields()
        if (id) {
          UserInvoiceHeaderApi.get(id).then(res => {
            Object.assign(this.editForm, res)
          })
        } else {
          Object.assign(this.editForm, defaultForm())
        }
      })
    },
    /**
     * 点击保存信息
     */
    handleSave () {
      this.saveLoading = true
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          UserInvoiceHeaderApi.save(this.editForm).then(res => {
            this.saveLoading = false
            this.dialogVisible = false
            this.handleGetTableInfo()
          })
        }
      })
    }
  },
  created () {
    this.handleGetTableInfo()
  }
}
</script>

<style scoped>
.invoice-input {
  width: 300px;
}
</style>
