<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-form :inline="true" :model="searchForm" label-width="80px">
          <el-form-item>
            <el-input v-model="searchForm.orderNo" placeholder="订单号" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <dict-select v-model="searchForm.orderStatus" dict-name="amsOrderStatusRecharge"
                         placeholder="订单状态"></dict-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="searchForm.startTime"
                type="date"
                unlink-panels
                placeholder="创建时间起"
                value-format="yyyy-MM-dd"
                style="width: 187px"
                :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="searchForm.endTime"
                type="date"
                unlink-panels
                placeholder="创建时间止"
                value-format="yyyy-MM-dd"
                style="width: 187px"
                :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="mainTable.rows"
            v-loading="mainTable.loading"
            style="width: 100%">
          <el-table-column prop="orderNo" key="orderNo" label="订单编号" width="220"></el-table-column>
          <el-table-column prop="orderDesc" key="orderDesc" label="订单描述" width="140"></el-table-column>
          <el-table-column prop="orderRemark" key="orderRemark" label="订单备注" width="180"></el-table-column>
          <el-table-column prop="totalAmount" key="totalAmount" label="订单金额"></el-table-column>
          <el-table-column prop="payAmount" key="payAmount" label="实付金额"></el-table-column>
          <el-table-column prop="orderStatus" key="orderStatus" label="订单状态" width="100">
            <template slot-scope="scope">
              <dict-text :code="scope.row.orderStatus" dict-name="amsOrderStatusRecharge"></dict-text>
            </template>
          </el-table-column>
          <el-table-column prop="payWay" key="payWay" label="支付方式" width="100">
            <template slot-scope="scope">
              <dict-text :code="scope.row.payWay" dict-name="amsPayWay"></dict-text>
            </template>
          </el-table-column>
          <el-table-column prop="accountName" key="accountName" label="汇款户名"></el-table-column>
          <el-table-column prop="accountCardNumber" key="accountCardNumber" label="汇款银行卡号"
                           width="200"></el-table-column>
          <el-table-column prop="payTime" key="payTime" label="支付时间" width="140"></el-table-column>
          <el-table-column prop="creationTime" key="creationTime" label="创建时间" width="140"></el-table-column>
          <el-table-column prop="tradeNo" key="tradeNo" label="交易单号" width="220"></el-table-column>
          <el-table-column prop="cancelTime" key="cancelTime" label="取消时间" width="140"></el-table-column>
          <el-table-column prop="cancelRemark" key="cancelRemark" label="取消备注"></el-table-column>
          <el-table-column
              label="操作"
              fixed="right"
              width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.orderStatus === 10">
                <el-button type="text" @click="handleCancel(scope.row.id)">取消</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10,20,50,100]"
            :page-size="searchForm.size"
            :background="true"
            layout="total, sizes, prev, pager, next"
            :total="mainTable.total">
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { AccountOrderApi } from '@/api'
import { SHORTCUTS } from '@/utils/contants'
import Dictionary from '@/utils/dictionary'

export default {
  name: 'OrderRemittance',
  data () {
    return {
      searchForm: {
        page: 1,
        size: 10,
        orderNo: '',
        tradeNo: '',
        orderType: Dictionary.amsOrderType.remittance.code,
        orderStatus: '',
        payWay: '',
        payStatus: '',
        billStatus: '',
        startTime: '',
        endTime: ''
      },
      mainTable: {
        loading: false,
        rows: [],
        total: 0
      },
      pickerOptions: {
        shortcuts: SHORTCUTS
      }
    }
  },
  methods: {
    handleSearch () {
      this.mainTable.loading = true
      const params = {
        ...this.searchForm,
        page: this.searchForm.page - 1
      }
      AccountOrderApi.getOrderList(params).then(result => {
        this.mainTable.rows = result.rows
        this.mainTable.total = result.total
        this.mainTable.loading = false
      })
    },
    handleSizeChange (size) {
      this.searchForm.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.searchForm.page = page
      this.handleSearch()
    },
    handleCancel (id) {
      this.$confirm('确定要取消订单吗？', '提示', {
        type: 'warning'
      }).then(() => {
        AccountOrderApi.cancelOrder({ orderId: id }).then(() => {
          this.$message.success('取消成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handleTabClick () {
      this.handleSearch()
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style>

</style>
