<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never" class="card-alert">
        <el-alert type="info" :closable="false">
          <template slot="title">
            绑定关系：将一个用户和员工的绑定关系，指向一个新的员工
          </template>
        </el-alert>
      </el-card>
      <el-card shadow="never">
        <el-row>
          <el-col>
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="200px">
              <el-form-item label="选择用户" prop="userId">
                <remote-select v-model="editForm.userId"
                               width="380"
                               width-input="400px"
                               placement="bottom"
                               url="/user/list"
                               :columns="[{field:'userNo',name:'用户编号'},{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                               label="nickName"
                               value-key="id"
                               :size="5"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item label="选择员工" prop="employeeId">
                <remote-select v-model="editForm.employeeId"
                               width="380"
                               width-input="400px"
                               placement="bottom"
                               url="/ams/user/list"
                               :columns="[{field:'displayName',name:'员工姓名'},{field:'mobile',name:'手机号'}]"
                               label="displayName"
                               value-key="id"
                               :size="5"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleToggleBinding">确认</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { UserApi } from '@/api'

export default {
  name: 'UserMigrateRelation',
  data () {
    return {
      editForm: {
        employeeId: '',
        userId: ''
      },
      rules: {
        userId: [
          {
            required: true,
            message: '请选择用户',
            trigger: 'change'
          }
        ],
        employeeId: [
          {
            required: true,
            message: '请选择用户',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    // 切换绑定
    handleToggleBinding () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm('将要转移员工和会员的绑定关系，是否确认?', '提示', {
            type: 'warning'
          }).then(() => {
            // 管理员id
            const staff = this.editForm.employeeId
            // 用户账号
            const userid = this.editForm.userId
            const par = {
              amsUserId: staff,
              memberId: userid
            }
            UserApi.migrate(par).then(() => {
              this.$message.success('关系转移成功')
            })
          })
        }
      })
    }
  }
}
</script>

<style>
</style>
