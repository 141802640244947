<template>
  <div>
    <div class="layout-main-body">
      <el-card shadow="never">
        <div>
          <!--对公汇款-->
          <el-result icon="success" title="提交成功" sub-title="汇款户名、银行信息正确的情况下1天内可到账，最快2小时，请耐心等待" v-if="source === '1'">
            <template slot="extra">
              <el-button type="primary" @click="handleGotoRechargeBalancePage">继续充值</el-button>
              <el-button plain @click="handleGotoOrderPage">查看订单</el-button>
            </template>
          </el-result>

          <!--在线支付-->
          <el-result icon="success" title="支付成功" v-if="source === '2'">
            <template slot="extra">
              <el-button type="primary" @click="handleGotoRechargeVipCardPage">继续购买VIP年卡</el-button>
              <el-button plain @click="handleGotoOrderPage">查看订单</el-button>
            </template>
          </el-result>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderPayResult',
  data () {
    return {
      source: ''
    }
  },
  methods: {
    handleGotoRechargeBalancePage () {
      this.$router.back()
    },
    handleGotoOrderPage () {
      this.$router.replace('/account/order')
    },
    handleGotoRechargeVipCardPage () {
      this.$router.replace('/account/recharge/vip/card')
    }
  },
  created: function () {
    this.source = this.$route.query.source
  }
}
</script>

<style scoped lang="less">

</style>
