<template>
  <el-card shadow="never" class="report-card">
    <div slot="header" class="clearfix report-card-header">
      <span>操作手册</span>
    </div>
    <div>
      <div>
        <el-link :href="link" target="_blank" type="primary"
                 underline>
          素客通代理商系统操作手册 <i class="fa-solid fa-arrow-up-right-from-square"></i>
        </el-link>
      </div>
      <div class="password">
        <span>密码：{{ password }}</span>
      </div>
      <div class="btn-wrapper">
        <el-button plain @click="handleCopy">复制链接和密码</el-button>
      </div>
    </div>
  </el-card>
</template>
<script>

export default {
  name: 'DocCard',
  data () {
    return {
      link: 'https://www.yuque.com/books/share/97ea24e8-3406-493d-9423-fb520604e0a5',
      password: 'kxp1'
    }
  },
  methods: {
    handleCopy () {
      this.$copyText(`${this.link}《素客通代理商系统操作手册》密码：${this.password}`).then(() => {
        this.$message.success('复制成功')
      })
    }
  }
}
</script>

<style scoped lang="less">
.password {
  margin-top: 10px;
  font-size: 14px;
}

.btn-wrapper {
  margin-top: 20px;
}

.report-card {
  min-height: 200px;
}
</style>
