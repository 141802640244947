<template>
  <div>
    <page-header has-back></page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="10">
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="150px">
              <el-form-item label="旧密码" prop="oldPassword">
                <el-input type="password" v-model="editForm.oldPassword" class="hy-input"></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model="editForm.newPassword" class="hy-input"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="confirmPassword">
                <el-input type="password" v-model="editForm.confirmPassword" class="hy-input"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSave" :loading="editLoading">保存</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>

import { SysUsersApi } from '@/api'

export default {
  name: 'UserPasswordModify',
  data () {
    return {
      editForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: '请输入旧密码',
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: '请输入确认密码',
            trigger: 'blur'
          }
        ]
      },
      editLoading: false
    }
  },
  methods: {
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          SysUsersApi.savePassword(this.editForm).then(() => {
            this.editLoading = false
            this.$message.success('修改成功')
            this.$store.dispatch('logout').then(() => {
              this.$store.dispatch('tagsView/delAllViews').then(() => {
                this.$router.push('/login')
              })
            })
          }).catch(() => {
            this.editLoading = false
          })
        } else {
          return false
        }
      })
    }
  },
  created: function () {
  }
}
</script>

<style scoped>

</style>
