<template>
  <div>
    <page-header></page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col>
            <el-tabs v-model="tabActiveName" @tab-click="handleTabsClick">
              <el-tab-pane label="余额充值" name="1">
                <order-recharge ref="table1"></order-recharge>
              </el-tab-pane>
              <el-tab-pane label="VIP年卡购买" name="2">
                <order-vip-card ref="table2"></order-vip-card>
              </el-tab-pane>
              <el-tab-pane label="对公汇款" name="3">
                <order-remittance ref="table3"></order-remittance>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>

import OrderRecharge from './components/OrderRecharge'
import OrderRemittance from './components/OrderRemittance'
import OrderVipCard from './components/OrderVipCard'

export default {
  name: 'OrderList',
  components: {
    OrderRecharge,
    OrderRemittance,
    OrderVipCard
  },
  data () {
    return {
      tabActiveName: '1'
    }
  },
  methods: {
    handleTabsClick (item) {
      this.$refs[`table${item.name}`].handleSearch()
    }
  },
  created: function () {
  }
}
</script>

<style>

</style>
