<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" :model="searchForm">
              <el-form-item>
                <el-input v-model="searchForm.text" placeholder="关键字" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.status" placeholder="状态" clearable>
                  <el-option :value="1" label="申请中"></el-option>
                  <el-option :value="2" label="审核通过"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button @click="handleCurrentChange(1)">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" label="#" width="40" align="center"></el-table-column>
              <el-table-column prop="name" label="旧用户" width="300">
                <template v-slot="scope">
                  {{ scope.row.oldUserNickName }}({{ scope.row.oldUserMobile }})
                </template>
              </el-table-column>
              <el-table-column prop="code" label="新用户" width="300">
                <template v-slot="scope">
                  {{ scope.row.newUserNickName }}({{ scope.row.newUserMobile }})
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                  {{ scope.row.status === 2 ? '已审核' : '申请中' }}
                </template>
              </el-table-column>
              <el-table-column prop="creationTime" label="申请时间" width="140"></el-table-column>
              <el-table-column prop="creatorName" label="申请人"></el-table-column>
              <el-table-column prop="creatorDeptName" label="申请人部门" width="200"></el-table-column>
              <el-table-column prop="auditorName" label="审核人"></el-table-column>
              <el-table-column prop="auditTime" label="审核时间" width="140"></el-table-column>
              <el-table-column
                  label="操作"
                  fixed="right"
                  width="90">
                <template slot-scope="scope">
                  <el-button @click="handleAuditPass([scope.row.id])" type="text" v-if="scope.row.status !== 2">审核通过
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { UserMigrateLogApi } from '@/api'

export default {
  name: 'UserMigrateLog',
  data () {
    return {
      searchForm: {
        text: '',
        status: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false
    }
  },
  methods: {
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size
      }
      if (this.searchForm.text) {
        params.text = this.searchForm.text
      }
      if (this.searchForm.status) {
        params.status = this.searchForm.status
      }
      UserMigrateLogApi.getList(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleAuditPass (ids) {
      this.$confirm('确定要审核通过吗？', '提示', {
        type: 'warning'
      }).then(() => {
        UserMigrateLogApi.audit(ids).then(() => {
          this.$message.success('操作成功')
          this.handleCurrentChange(1)
        })
      })
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style lang="less">
</style>
