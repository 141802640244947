<template>
  <iframe id="myIframe" src="http://dldoc.suketong.cn"></iframe>
</template>

<script>
import { iframeResize } from 'iframe-resizer'

export default {
  name: 'Doc',
  data () {
    return {}
  },
  methods: {},
  mounted () {
    iframeResize({ log: true }, '#myIframe')
  }
}
</script>

<style scoped lang="less">
iframe {
  width: 1px;
  min-width: 100%;
  border: 0;
  min-height: 1400px;
}
</style>
