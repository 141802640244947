<template>
  <div>
    <page-header>
      <template v-slot:right>
        <router-link to="/cms/video/edit">
          <el-button type="primary">添加视频</el-button>
        </router-link>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <content-condition @change="handleConditionChange"></content-condition>
        <div v-loading="loading">
          <div v-if="rows.length > 0">
            <el-row :gutter="10">
              <el-col :span="12" v-for="row in rows" :key="row.id">
                <el-card shadow="hover" :body-style="{ padding: '10px' }" class="video-item">
                  <el-row>
                    <el-col :span="6">
                      <div
                          :style="{ 'background-image':'url(' + row.videoCoverUrl + ')','background-color': 'rgb(0, 0, 0);', 'background-size': '100%;', 'background-position-y': '50%;', 'background-repeat':'no-repeat;' }"
                          class="video-cover" @click="playVideo(row.videoUrl)">
                        <!--                  <span class="num">{{ getVideoTime(row.videoTime) }}</span>-->
                      </div>
                    </el-col>
                    <el-col :span="18" class="video-info">
                      <div class="title">{{ row.title }}</div>
                      <div class="rpt">
                        <div>
                          <i class="fa fa-eye" aria-hidden="true" title="浏览次数"></i><span>{{ row.countView || 0 }}</span>
                        </div>
                        <div>
                          <i class="fa fa-share-alt" aria-hidden="true" title="分享次数"></i><span>{{
                            row.countShare || 0
                          }}</span>
                        </div>
                        <div>
                          <i class="fa fa-cloud-download" aria-hidden="true" title="下载次数"></i><span>{{
                            row.countCreate || 0
                          }}</span>
                        </div>
                      </div>
                      <div>{{ row.categoryName }}</div>
                      <div class="time">
                        创建时间：{{ row.creationTime }}
                        <span v-if="row.publishTime">发布时间：{{ row.publishTime }}</span>
                      </div>
                      <div class="status">
                  <span :class="{publish: row.status === 2,normal: row.status !== 2}">{{
                      getRowStatus(row.status)
                    }}</span>
                        <span class="publish" v-if="row.isPush">已推送</span>
                      </div>
                      <div class="toolbar">
                        <el-button type="text" @click="handlePublish([row.id])" v-if="row.status !== 2">发布视频</el-button>
                        <el-button type="text" @click="handleOut([row.id])" v-if="row.status === 2">下架视频</el-button>
                        <el-button type="text" @click="gotoEditPage(row.id)">修改信息</el-button>
                        <el-button type="text" @click="handleRemove([row.id])" v-if="row.status !== 2">删除视频</el-button>
                      </div>
                    </el-col>
                  </el-row>
                </el-card>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[10,20,50,100]"
                    :page-size="size"
                    :background="true"
                    layout="total, sizes, prev, pager, next"
                    :total="total">
                </el-pagination>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="60%" :before-close="handleViewPlayCode"
                   class="video-player-dialog">
          <video-player class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions">
          </video-player>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
import { CmsVideoApi } from '@/api'
import { MATERIAL_STATUS } from '@/utils/contants'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import ContentCondition from './components/ContentCondition'

momentDurationFormatSetup(moment)

export default {
  name: 'Video',
  components: { ContentCondition },
  data () {
    return {
      searchForm: {
        title: '',
        status: '',
        categoryId: '',
        pushStatus: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      selectRows: [],
      dialogVisible: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
          progressControl: {
            seekBar: true
          }
        }
      }
    }
  },
  methods: {
    handleConditionChange (data) {
      this.searchForm = { ...data }
      this.handleCurrentChange(1)
    },
    handleSearch () {
      this.loading = true
      const params = {
        ...this.searchForm,
        page: this.page - 1,
        size: this.size
      }
      CmsVideoApi.list(params).then(result => {
        const data = result.rows
        if (data && data.length > 0) {
          this.rows = data
          this.total = result.total
        } else {
          this.rows = []
          this.total = 0
        }
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleSelectionChange (val) {
      this.selectRows = val
    },
    getRowStatus (status) {
      return MATERIAL_STATUS.getText(status)
    },
    handleRemove (ids) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsVideoApi.remove(ids).then(() => {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handlePublish (ids) {
      this.$confirm('确定要发布吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsVideoApi.publish(ids).then(() => {
          this.$message.success('发布成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    handleOut (ids) {
      this.$confirm('确定要下架吗？', '提示', {
        type: 'warning'
      }).then(() => {
        CmsVideoApi.out(ids).then(() => {
          this.$message.success('操作成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    gotoEditPage (id) {
      this.$router.push({
        name: 'VideoEdit',
        query: {
          id: id
        }
      })
    },
    getVideoTime (time) {
      return moment.duration(time, 'seconds').format('mm:ss', {
        trim: false
      })
    },
    playVideo (url) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.videoPlayer.player.src(url) // 重置进度条复制代码
        this.$refs.videoPlayer.player.play() // 播放
      })
    },
    handleViewPlayCode (done) {
      this.$refs.videoPlayer.player.pause() // 暂停
      done()
    },
    handleSortClick () {
      if (this.searchForm.sort === 'desc') {
        this.searchForm.sort = 'asc'
      } else {
        this.searchForm.sort = 'desc'
      }
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style lang="less">
.video-cover {
  flex: 0 0 auto;
  position: relative;
  height: 200px;
  width: 150px;
  border-radius: 4px;
  background-color: rgba(22, 24, 35, 0.3);
  overflow: hidden;
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  background-size: 100%;
  background-position-y: 50%;
  background-repeat: no-repeat;

  .num {
    position: absolute;
    right: 10px;
    bottom: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
  }
}

.video-info {
  font-size: 12px;

  > div {
    margin-top: 10px;
  }

  .title {
    font-size: 16px !important;
    line-height: 22px;
    font-weight: 700;
    color: #161823;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .rpt {
    margin-top: 10px;

    div {
      display: inline-flex;
      align-items: center;
      margin-right: 8px;
      color: #1C1F2399;
    }

    span {
      margin-left: 5px;
    }
  }

  .time {

  }

  .status {
    .publish {
      color: #00a65a;
    }

    .normal {
      color: #e12542;
    }

    span {
      margin-right: 10px;
    }
  }

  .toolbar {
    bottom: 0;
    position: absolute;
  }
}

.video-item {
  margin-top: 10px;
}

.video-player-dialog {
  .el-dialog__header {
    background: #000;
  }

  .el-dialog__body {
    padding: 15px 0 0 0;
    background: #000;
  }

  .el-dialog__headerbtn {
    i {
      font-size: 16px;
      color: #fff;
    }
  }
}

</style>
